import React from 'react';
import {Form} from "semantic-ui-react";

const MaskInput = ({value, name, error, onChange, placeholder = '', label, required, regex, onBlur}) => {
    const handleChange = (e) => {
        const newValue = e.target.value || '';

        if (regex.test(newValue) || !newValue) {
            onChange({[name]: newValue});
        }
    };

    return (
        <Form.Input
            id={`input_${name}`}
            required={required}
            error={error}
            label={label || name}
            placeholder={placeholder}
            value={value || ''}
            onChange={handleChange}
            onBlur={onBlur}
        />
    );
};

export default MaskInput;